<template>
  <div class="container br-footer">
    <div class="container-wrapper-text">
      <p>Written in Vue JS without any CSS framework. @banyurachman</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "@/assets/global.scss";

  .br-footer {
    position: relative;
    overflow: hidden;

    .container-wrapper-text {
      margin: 0 auto;
    }

    p {
      font-weight: bold;
      color: #bbbbbb;
      font-size: 12px;
      line-height: 20px;
    }

    &:before {
      content: "";
      width: 50%;
      height: 50px;
      position: absolute;
      left: 0;
      top: 100%;
      box-shadow: 0 0 75px #{$blue}88;
    }

    &:after {
      content: "";
      width: 50%;
      height: 50px;
      position: absolute;
      right: 0;
      top: 100%;
      box-shadow: 0 0 75px #{$yellow}88;
    }
  }
</style>
