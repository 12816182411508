<template>
  <div class="br-title">
    <h1>{{ text }}</h1>
  </div>
</template>

<script>

export default {
  name: 'Title',
  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/global.scss";

  @keyframes sunrise {
    0% {
      transform: translateY(0) translateX(-8px);
    }
    100% {
      transform: translateY(-8px) translateX(-8px);
    }
  }

  .br-title {
    position: relative;
    margin-top: 32px;
    margin-bottom: 64px;

    h1 {
      margin: 0;
      &:before {
        content: "";
        @include square(50px);
        background: var(--title-sun);
        position: absolute;
        @include rounded;
        z-index: var(--title-sun-index);
        box-shadow: 0 0 50px #{$yellow}88;
        transition: all 0.5s ease-in-out;
        animation: sunrise 1.5s infinite alternate;
      }
      &:after {
        content: ".";
        color: $blue;
      }
    }

    &:hover {
      h1:before {
        box-shadow: 0 0 100px $yellow;
        opacity: 0.7;
      }
    }
  }
</style>
