<template>
  <div class="wrapper">
    <!-- <a aria-label="link to Kallan page" aria-describedby="Kallan page" href="/kallan" class="wrapper__avatar">
      <img src="@/assets/img/avatar.jpg" alt="">
      <div class="wrapper__avatar-ornament ornament1"></div>
      <div class="wrapper__avatar-ornament ornament2"></div>
      <div class="wrapper__avatar-ornament ornament3"></div>
      <div class="wrapper__avatar-ornament ornament4"></div>
    </a> -->
    <router-link to="/kallan" aria-label="link to Kallan page" aria-describedby="Kallan page" class="wrapper__avatar">
      <img src="@/assets/img/avatar.jpg" alt="">
      <div class="wrapper__avatar-ornament ornament1"></div>
      <div class="wrapper__avatar-ornament ornament2"></div>
      <div class="wrapper__avatar-ornament ornament3"></div>
      <div class="wrapper__avatar-ornament ornament4"></div>
    </router-link>
    <div class="wrapper__content">
      <h1 class="wrapper__content-title dark">Banyu Rachman</h1>
      <div class="wrapper__content-subtitle">
        <p>UX Engineer. Love to design. Write the code.</p>
        <p>I have worked for an <a href="https://solveeducation.org/" target="_blank">edtech startup</a>, an <a href="https://dataon.com/" target="_blank">enterprise B2B tech company</a>,
        and a <a href="http://blibli.com/" target="_blank">unicorn e-commerce</a>. I'm working both as an engineer and a designer.</p>
      </div>
      <div class="wrapper__content-extra">
        <Button class="desktop-only" text="Contact" url="mailto: banyurachman95@gmail.com" newtab>
          <font-awesome-icon :icon="['fas', 'envelope-open']" />
        </Button>
        <span>
          <Button class="mobile-only" url="https://www.linkedin.com/in/banyurachman/" transparent newtab>
            <font-awesome-icon :icon="['fas', 'envelope-open']" />
          </Button>
          <Button url="https://www.linkedin.com/in/banyurachman/" transparent newtab>
            <font-awesome-icon :icon="['fab', 'linkedin-in']" />
          </Button>
          <Button url="https://codepen.io/banyurachman/pens/public" transparent newtab>
            <font-awesome-icon :icon="['fab', 'codepen']" />
          </Button>
          <Button url="https://github.com/banyurachman" transparent newtab>
            <font-awesome-icon :icon="['fab', 'github']" />
          </Button>
          <Button url="https://www.figma.com/@banyurachman" transparent newtab>
            <font-awesome-icon :icon="['fab', 'figma']" />
          </Button>
          <Button url="https://www.instagram.com/banyurachman95/" transparent newtab>
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </Button>
          <Button url="https://twitter.com/banyurachman" transparent newtab>
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </Button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "@/assets/global.scss";
  
  @mixin ornament ($speed, $direction, $delay) {
    @include square(250px);
    position: absolute;
    z-index: -1;
    @include center;
    animation: clockwise $speed linear infinite $direction;

    &:after {
      content: '';
      position: absolute;
      transition-delay: $delay;
      @include rounded;
      @content
    }
  }

  @keyframes clockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;
    max-width: 1000px;
    width: 100%;
    align-items: center;
    justify-content: center;
    @include phone {
      margin-top: 32px;
    }

    &__avatar {
      flex: 0 0 350px;
      z-index: 1;
      @include center;
      transform: scale(1.2);

      @include phone {
        transform: scale(1);
      }

      &-ornament {
        background: none;
        &:after {
          transition: all 0.5s ease-in-out;
        }

        &.ornament1 {
          @include ornament(10s, normal, 0.2s) {
            @include square(215px);
            background: var(--ornament1);
            box-shadow: 0px 0px 200px var(--ornament1-shadow);
            top: -5px;
          }
        }
        
        &.ornament2 {
          @include ornament(5s, reverse, 0.6s) {
            @include square(150px);
            background: var(--ornament2);
            top: 0px;
            box-shadow: 0px 0px 50px var(--ornament2-shadow);
          }
        }

        &.ornament3 {
          @include ornament(2s, normal, 0.4s) {
            @include square(200px);
            border: var(--ornament3) 3px solid;
            top: 10px;
          }
        }
      }

      img {
        @include square(200px);
        border-radius: 100%;
        box-shadow: 0px 0px 50px #44444444;
        transition: all 0.2s ease-in-out;
      }
    }

    &__content {
      flex: 0 0 500px;
      text-align: left;
      max-width: calc(100vw - 64px);

      &-title {
        margin-top: 0;
        span {
          color: $yellow;
        }
        &:after {
          content: ".";
          color: $yellow;
        }
      }

      &-subtitle {
        // margin: 32px 0;
        p {
          margin: 8px 0;
        }
        a {
          text-decoration: none;

          &:hover {
            background: $yellow;
            color: $black;
          }
        }
      }

      &-extra {
        margin-top: 32px;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
      }
    }

    &:hover {
      .wrapper__avatar-ornament {
        &:after {
          transform: scale(1.5);
        }
        &.ornament1:after{
          box-shadow: 0px 0px 300px var(--ornament1);
        }
        &.ornament2:after{
          background: $yellow;
          box-shadow: 0px 0px 50px $yellow;
        }
      }

      img {
        transform: rotate(15deg);
      }
    }
  }

  .mobile-only {
    display: none !important;

    @include phone {
      display: inline !important;
    }
  }

  .desktop-only {
    @include phone {
      display: none !important;
    }
  }
</style>
