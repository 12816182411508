import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import "@/assets/global.scss"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpen, faArrowUp, faHome, faChevronDown, faCode, faMoon, faAdjust, faBorderStyle, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faCodepen, faGithub, faInstagram, faTwitter, faFigma } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faMoon,
  faAdjust,
  faEnvelopeOpen,
  faLinkedinIn,
  faCodepen,
  faGithub,
  faInstagram,
  faTwitter,
  faArrowUp,
  faHome,
  faFigma,
  faChevronDown,
  faCode,
  faBorderStyle,
  faBars,
  faTimes
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Button from '@/components/items/Button.vue'
import Title from '@/components/items/Title.vue'
import Card from '@/components/items/Card.vue'
import Separator from '@/components/items/Separator.vue'
import SeparatorVertical from '@/components/items/SeparatorVertical.vue'
import Footer from '@/components/Footer.vue'
import Menu from '@/components/Menu.vue'

Vue.component('Button', Button);
Vue.component('Title', Title);
Vue.component('Card', Card);
Vue.component('Footer', Footer);
Vue.component('Menu', Menu);
Vue.component('Separator', Separator);
Vue.component('SeparatorVertical', SeparatorVertical);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
