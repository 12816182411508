<template>
  <div class="br-menu">
    <div class="br-menu-wrapper">
      <a href="/#home" class="br-menu__head">
        <Title text="BR"></Title>
      </a>
      <div class="br-menu__item">
        <a href="/#home">Home</a>
        <a href="/#experiences">Experiences</a>
        <a href="/#works">Works</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/global.scss";

  .br-menu {
    background: var(--white-menu);
    // border-bottom: #{$blue}22 1px solid;
    width: 100%;
    position: fixed;
    z-index: 999;
    backdrop-filter: blur(15px);

    @include phone {
      display: none;
    }

    a {
      font-family: 'Kanit', Arial, Helvetica, sans-serif !important;
      text-decoration: none;
    }

    &-wrapper {
      margin: 0 auto;
      padding: 0 8px;
      max-width: 800px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone {
        display: none;
      }
    }

    &__head {
      .br-title {
        margin: 0;
        transform: scale(0.6);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      height: 64px;
      a {
        color: var(--black);
        font-weight: 500;
        display: flex;
        align-items: center;
        height: 100%;
        gap: 8px;
        padding: 0 16px;
        position: relative;
        border-bottom: #0000 3px solid;

        svg {
          transform: rotate(90deg) scale(0.8);
        }

        &:hover {
          background: var(--white-menu);
          border-bottom: $yellow 3px solid;
        }
      }
    }
  }
</style>
