<template>
  <div>
    <Menu />
    <div class="center container" id="home">
      <HeroSection/>
    </div>
    <!-- <div class="container">
      <PhotoSection />
    </div> -->
    <separator id="experiences" />
    <div class="container">
      <Title text="Experiences" />
      <div class="container-wrapper-text">
        <p>
          Learning from various teams is an amazing opportunity.
          Each experience gave me its unique value.
          These are the teams that I'm glad to work with.
          So, what's next?
        </p>
      </div>
      <ExperienceSection />
    </div>
    <!-- <separator />
    <div class="container">
      <Title text="Daily Tools" />
      <div class="container-wrapper-text">
        <p>
          I'm working as engineer and as designer as well. So, here are some tools I'm using daily. Some are similar with the skills that you need? You should contact me then.
        </p>
      </div>
      <ToolsSection />
    </div> -->
    <!-- <separator /> -->
    <separator id="works" />
    <div class="container">
      <Title text="My Works" />
      <div class="container-wrapper-text">
        <p>
          I have specialization to work on visual detailing for the front-end works. It means that I deep focus on deliver the pixel-perfect implementation from the delivered design. I love to play around with CSS to create something.
        </p>
        <Codepen />
        <Button text="Visit my CodePen page" url="https://codepen.io/banyurachman/pens/public" newtab>
          <font-awesome-icon :icon="['fab', 'codepen']" />
        </Button>
      </div>
      <SeparatorVertical />
      <div class="container-wrapper-text">
        <p>
          And here are some of my design works.
          Other works are private or not published here yet.
          So, stay tuned! Will update the other & future works here.
        </p>
      </div>
      <WorksCardSection />
      <SeparatorVertical />
      <div class="container-wrapper-text">
        <p>
          Besides two private Figma plugins for Blibli Design team that I created with my team, we also published a public
          plugin that you can find in the Figma Community.
        </p>
        <a href="https://www.figma.com/community/plugin/1157653871805394973/Sticky-Note-Maker" target="_blank">
          <img width="100%" src="@/assets/img/snm.png" alt="" />
        </a>
        <br>
        <Button text="Check this out and try!"
          url="https://www.figma.com/community/plugin/1157653871805394973/Sticky-Note-Maker" newtab>
          <font-awesome-icon :icon="['fab', 'figma']" />
        </Button>
      </div>
    </div>
    <separator />
    <div class="container">
      <Title text="Thank You" />
      <div class="container-wrapper-text">
        <p>
          Thanks for being here.
          Please let me know if you have some things to discuss related to design or front-end project. Contact me by email or some social medias I put on top.
        </p>
        <Button text="Bring me to the top, hurry!" :clickFunction="this.goUp">
          <font-awesome-icon :icon="['fas', 'arrow-up']" />
        </Button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection.vue'
import WorksCardSection from '@/components/WorksCardSection.vue'
// import PhotoSection from '@/components/PhotoSection.vue'
import ExperienceSection from '@/components/ExperienceSection.vue'
import Codepen from '@/components/Codepen.vue'
import Separator from '../components/items/Separator.vue'

export default {
  name: 'Home',
  components: {
    HeroSection,
    WorksCardSection,
    // PhotoSection,
    ExperienceSection,
    Codepen,
    Separator
},
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    goUp () {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/global.scss";

  #home {
    min-height: 50vh;
    padding-top: 154px;
    @include phone {
      padding-top: 64px;
      padding-bottom: 0;
      overflow: hidden;
    }
  }

  .container-wrapper-text {
    > button.br-button {
      margin-top: 16px;
    }
  }
</style>
