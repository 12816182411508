<template>
  <div class="br-separator-vertical">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>

export default {
  name: 'SeparatorVertical'
}
</script>

<style scoped lang="scss">
  @import "@/assets/global.scss";

  .br-separator-vertical {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    > div {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--button-background);
    }
  }
</style>
