<template>
  <div class="container-wrapper br-works">
    <Card
      title="by.U Alternative Design"
      desc="An alternative design for the digital cellular provider in Indonesia."
      tag="UX Design, UI Design" 
      url="https://www.behance.net/gallery/104240527/byU-Alternative-Design"
      newtab>
      <img class="br-card__thumbnail" src="@/assets/img/thumbnail-byu.png" alt="">
    </Card>
    <Card
      title="Slack Mobile App Redesign"
      desc="I have some problems with Slack mobile app. And this is how I solve it."
      tag="UX Design, UI Design" 
      url="https://www.behance.net/gallery/105094953/Slack-Mobile-App-Redesign"
      newtab>
      <img class="br-card__thumbnail" src="@/assets/img/thumbnail-slack.png" alt="">
    </Card>
    <Card
      title="Webse UI Design System"
      desc="An initial design system that I built for Solve Education's web-apps."
      tag="UI Design, Front-End Development" 
      url="https://www.behance.net/gallery/103913923/Webse-UI-Design-System"
      newtab>
      <img class="br-card__thumbnail" src="@/assets/img/thumbnail-webse.png" alt="">
    </Card>
  </div>
</template>

<script>
export default {
  name: 'WorksCardSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "@/assets/global.scss";
  
  .br-works {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
</style>
