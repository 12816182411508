<template>
  <div class="br-experience-item" :class="style">
    <div class="br-experience-item__head" tabindex="0" @keydown.enter="itemSwitch" @click="itemSwitch">
      <div class="br-experience-item__head-content">
        <div class="br-experience-item__company">{{ company }}</div>
        <div class="br-experience-item__title">
          {{ title }}
          <span v-if="parttime" class="br-experience-item__parttime">{{ parttime }}</span>
          <span class="br-experience-item__period">{{ period }}</span>
        </div>
      </div>
      <div class="br-experience-item__head-trigger">
        <font-awesome-icon :icon="['fas', 'chevron-down']" />
      </div>
    </div>
    <div class="br-experience-item__content">
      <p class="br-experience-item__desc">
        {{ desc }}
      </p>
      <div class="br-experience-item__tools">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Experience',
  props: {
    company: String,
    period: String,
    title: String,
    desc: String,
    parttime: String,
    open: Boolean
  },
  computed: {
    style () {
      return {
        'opened': this.opened
      }
    }
  },
  data () {
    return {
      opened: this.open
    }
  },
  methods: {
    itemSwitch () {
      this.opened = !this.opened
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/global.scss";

  @keyframes box-shadow {
    0% {
      box-shadow: 0 0 0 0 #{$yellow}bb;
    }
    100% {
      box-shadow: 0 0 0 15px #{$yellow}00;
    }
  }

  .br-experience-item {
    text-align: left;
    padding-left: 32px;
    @include phone {
      padding-left: 24px;
    }
    border-left: 2px solid var(--experience-border);
    padding-bottom: 12px;
    position: relative;

    &:last-child {
      padding-bottom: unset;
      border-color: transparent;
      border-image: 
      linear-gradient(
        to bottom, 
        var(--experience-border), 
        rgba(0, 0, 0, 0)
      ) 1 100%;
    }

    > div:not(.br-experience-item__tools) {
      transform: translateY(-10px);
    }

    &:before {
      content: "";
      @include square(24px);
      background: $yellow;
      position: absolute;
      left: -13px;
      @include rounded;
      transition: all 0.2s ease-in-out;
      @include phone {
        @include square(20px);
        left: -11px;
      }
    }

    &:hover {
      &:before {
        animation: box-shadow 1s ease-in-out infinite;
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &:focus {
        outline: none;
      }

      &-trigger {
        transform: translateY(15px);
        svg {
          transition: transform .5s ease;
        }
      }
    }

    &__content {
      max-height: 0;
      overflow-y: clip;
      transition: max-height .5s ease;
      clip: auto;
      -webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%);
    }

    &__company {
      font-family: 'Kanit', Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 28px;
      @include phone {
        font-size: 28px;
      }
      // margin-bottom: 4px;

      span {
        font-family: 'Kanit', Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 32px;
      }
    }

    &__title {
      font-size: 18px;
      @include phone {
        font-size: 16px;
      }
      color: #888888;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 16px;
    }

    &__period, &__parttime {
      font-weight: 400;
      font-size: 12px;
      color: #888888;
      border: 1px var(--button-background) solid;
      border-radius: 8px;
      padding: 3px 7px;
    }

    &__parttime {
      background: #{$blue}22;
      color: $blue;
      border-color: #{$blue}44;
    }

    &__desc {
      margin-top: 0;
      margin-bottom: 24px;
    }

    &__tools {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      padding-bottom: 32px;

      img {
        @include square(32px);
        transition: all 0.2s ease-in-out;
        border-radius: 8px;

        &:hover {
          transform: rotate(15deg) scale(1.05);
        }
      }
    }

    &.opened {
      .br-experience-item {
        &__head {
          &-trigger svg {
            transform: rotate(180deg);
          }
        }
        &__content {
          max-height: 250px;

          @include phone {
            max-height: 500px;
          }
        }
      }
    }
  }
</style>
