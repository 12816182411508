import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Works from '../views/Works.vue'
import Kallan from '../views/Kallan.vue'
import Section404 from '../views/Section404.vue'

Vue.use(VueRouter)

const metaData = {
      title: 'Banyu Rachman Official Personal Website',
      metaTags: [
        {
          name: 'description',
          content: 'An official personal website for Banyu Rachman. A UX Engineer, a designer, a dad.'
        },
        {
          property: 'og:description',
          content: 'An official personal website for Banyu Rachman. A UX Engineer, a designer, a dad.'
        }
      ]
    }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: metaData
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: metaData
  },
  {
    path: '/works',
    name: 'Works',
    component: Works,
    meta: metaData
  },
  {
    path: '/kallan',
    name: 'Kallan',
    component: Kallan,
    meta: metaData
  },
  {
    path: '/*',
    name: '404',
    component: Section404,
    meta: metaData
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
