<template>
  <div id="app" :class="classes">
    <router-view/>
    <div class="theme-switcher" @click="switchTheme">
      <font-awesome-icon v-if="this.darkMode" :icon="['fas', 'moon']" />
      <font-awesome-icon v-if="!this.darkMode" class="off" :icon="['fas', 'adjust']" />
      <span>{{ this.darkMode ? "Dark" : "Light" }}</span>
    </div>
    <div class="outline-switcher" @click="switchOutline">
      <font-awesome-icon v-if="this.darkMode" :icon="['fas', 'border-style']" />
      <font-awesome-icon v-if="!this.darkMode" class="off" :icon="['fas', 'border-style']" />
      <span>{{ this.outlineActive ? "Outline" : "Clean" }}</span>
    </div>
    <div :class="menuClasses">
      <a v-if="this.isMenuOpened" href="/#home">Home</a>
      <a v-if="this.isMenuOpened" href="/#experiences">Experiences</a>
      <a v-if="this.isMenuOpened" href="/#works">Works</a>
      <div v-if="this.isMenuOpened" class="menu-separator"></div>
      <div v-if="this.isMenuOpened" class="menu-theme" @click="switchTheme">Dark Mode: {{ this.darkMode ? "On" : "Off" }}</div>
      <div v-if="this.isMenuOpened" class="menu-separator"></div>
      <div @click="switchMenu" class="menu-toggle">
        <font-awesome-icon v-if="!this.isMenuOpened" :icon="['fas', 'bars']" />
        <font-awesome-icon v-if="this.isMenuOpened" :icon="['fas', 'times']" />
        {{ this.isMenuOpened ? "Close" : "Menu"}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  props: {
    dark: {
      type: Boolean,
      // default: window.matchMedia('(prefers-color-scheme: dark)').matches
      default: false
    },
    outline: Boolean,
    isMenuOpened: Boolean
  },
  data: function () {
    return {
      darkMode: this.dark,
      outlineActive: this.outline
    }
  },
  // mounted () {
  //   window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  //     this.darkMode = event.matches
  //   });
  // },
  // destroyed () {
  //   window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', event => {
  //     this.darkMode = event.matches
  //   });
  // },
  computed: {
    classes () {
      return {
        'dark': this.darkMode,
        'outline': this.outlineActive
        // 'dark': false
      }
    },
    menuClasses() {
      return {
        'mobile-menu': true,
        'active': this.isMenuOpened
        // 'dark': false
      }
    },
    isDark () {
      return this.darkMode ? "Off" : "On"
    }
  },
  methods: {
    switchTheme () {
      this.darkMode = !this.darkMode
    },
    switchOutline () {
      this.outlineActive = !this.outlineActive
    },
    switchMenu() {
      this.isMenuOpened = !this.isMenuOpened
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
</style>
