<template>
  <button class="br-button" aria-label="social-media-button" :class="classes" @click="click">
    <slot />
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>

export default {
  name: 'Button',
  props: {
    text: String,
    url: String,
    newtab: Boolean,
    transparent: Boolean,
    clickFunction: Function
  },
  computed: {
    classes () {
      return {
        'transparent': this.transparent
      }
    }
  },
  methods: {
    click () {
      if (this.clickFunction) {
        this.clickFunction();
        return
      }
      if (!this.url) return
      if (this.newtab) {
        window.open(this.url);
        return
      }
      window.location.href = this.url;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/global.scss";

  .br-button {
    position: relative;
    display: inline-block;
    text-decoration: none;
    background: var(--button-background);
    padding: 9px 12px;
    border-radius: 10px;
    font-size: 14px;
    color: var(--black);
    box-shadow: 0px 10px 20px var(--button-shadow);
    border: 1px #dddddd solid;
    cursor: pointer;
    transition: all 0.1s;
    + .button {
      margin-left: 8px;
    }

    span {
      font-family: 'Kanit', Arial, Helvetica, sans-serif;
    }

    &:before, &:after {
      opacity: 0;
      height: 100%;
      width: 100%;
      content: '';
      outline: 1px solid $blue;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px;
      transition: all 0.2s ease-in-out;
    }

    &:after {
      outline: 1px solid $yellow;;
    }

    &:hover {
      &:before, &:after {
        opacity: 1;
        transform: translate(-4px, -4px);
      }
      &:after {
        transform: translate(4px, 4px);
      }
    }

    &:not(.transparent) + .transparent {
      margin-left: 8px;
    }

    &.transparent {
      background: none;
      border: none;
      box-shadow: none;

      + .button {
        margin-left: 2px;
      }

      &:hover {
        background: var(--button-background);
      }
    }

    svg + span {
      margin-left: 8px;
    }
  }
</style>
