<template>
  <div class="br-separator">
  </div>
</template>

<script>

export default {
  name: 'Separator'
}
</script>

<style scoped lang="scss">
  @import "@/assets/global.scss";

  @keyframes separator {
    0% {
      width: 50%;
      background: $blue;
    }

    100% {
      width: 25%;
      background: $yellow;
    }
  }

  .br-separator {
    width: 100%;
    background: var(--black);
    margin: 64px 0;
    @include phone {
      margin: 48px 0;
    }
    display: flex;
    justify-content: center;

    &:before {
      content: '';
      width: 50%;
      max-width: 800px;
      height: 1px;
      background: $blue;
      position: absolute;
      opacity: 0.3;
      animation: separator 3s ease-in-out infinite alternate;
    }
  }
</style>
