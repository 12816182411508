<template>
  <div class="br-card" tabindex="0" @keydown.enter="click" @click="click">
    <slot />
    <div class="br-card__content">
      <div class="br-card__content-title">{{ title }}</div>
      <p class="br-card__content-desc">{{desc}}</p>
      <div class="br-card__content-tag">{{tag}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Card',
  props: {
    title: String,
    desc: String,
    tag: String,
    url: String,
    newtab: Boolean
  },
  methods: {
    click () {
      if (!this.url) return
      if (this.newtab) {
        window.open(this.url);
        return
      }
      window.location.href = this.url;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/global.scss";

  .br-card {
    width: 350px;
    box-shadow: 0 0 50px var(--card-shadow);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: 1px solid var(--card-outline);

    position: relative;

    &__thumbnail {
      height: 250px;
      width: 100%;
      object-fit: cover;
      margin: 0;
      border-radius: 16px 16px 0 0;
    }

    &__content {
      padding: 24px;
      padding-top: 16px;
      text-align: left;
      border-radius: 16px;

      &-title {
        font-family: 'Kanit', Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 24px;
      }

      &-desc {
        font-family: 'Open Sans';
        line-height: 150%;
        color: #888888;
        font-size: 14px;
      }

      &-tag {
        color: #bbbbbb;
        font-size: 12px;
        font-weight: bold;
      }
    }

    &:before, &:after {
      opacity: 0;
      height: 100%;
      width: 100%;
      content: '';
      outline: 1px solid $blue;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 16px;
      transition: all 0.3s ease-in-out;
    }

    &:after {
      outline: 1px solid $yellow;;
    }

    &:hover {
      &:before, &:after {
        opacity: 1;
        transform: translate(-8px, -8px);
      }
      &:after {
        transform: translate(8px, 8px);
      }
    }
  }
</style>
