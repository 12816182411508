<template>
  <div class="container-wrapper-text br-experience">
    <Experience company="Blibli" title="Senior UX Engineer" period="Apr 2021 - Oct 2022; Jan 2023 - Now" desc="
            Leading Blibli's first design system for Android and iOS development and released some Figma plugins for the design team.
          ">
      <img src="@/assets/img/tools/vue.png" alt="vue">
      <img src="@/assets/img/tools/sass.png" alt="sass">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/git.png" alt="git">
      <img src="@/assets/img/tools/npm.png" alt="npm">
      <img src="@/assets/img/tools/vscode.png" alt="vscode">
      <img src="@/assets/img/tools/jira.png" alt="jira">
      <img src="@/assets/img/tools/bitbucket.png" alt="bitbucket">
      <img src="@/assets/img/tools/confluence.png" alt="confluence">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/teams.png" alt="teams">
    </Experience>
    <!-- <Experience company="Trakteer" title="UI/UX Designer" period="Nov 2020 - Now" parttime="Part-time" desc="
          I keep my design role by handling the side project from Trakteer. Here, I lead small thrid-party design team with 1 junior designer and 1 intern. We designed the mobile app and redesigned the web version as well.
        ">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/notion.png" alt="notion">
      <img src="@/assets/img/tools/docs.png" alt="docs">
    </Experience> -->
    <Experience company="Lummo" title="Senior UX Engineer" period="Oct 2022 - Nov 2022" desc="
            The first UX Engineer in the company. Helping both designers and engineers to build the design system, visual detailing, and prototyping.
          ">
      <img src="@/assets/img/tools/react.png" alt="react">
      <img src="@/assets/img/tools/tailwind.png" alt="tailwind">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/git.png" alt="git">
      <img src="@/assets/img/tools/npm.png" alt="npm">
      <img src="@/assets/img/tools/vscode.png" alt="vscode">
      <img src="@/assets/img/tools/github.png" alt="github">
      <img src="@/assets/img/tools/jira.png" alt="jira">
      <img src="@/assets/img/tools/confluence.png" alt="confluence">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/slack.png" alt="slack">
    </Experience>
    <!-- <Experience company="Blibli" title="Senior UX Engineer" period="Apr 2021 - Oct 2022" desc="
        I contributed for BLUE Design Language System project, also initiated and lead the new workflow for the development. Last, helped both designers and engineers for the visual detailing in Blibli Seller Center tribe.
      ">
      <img src="@/assets/img/tools/vue.png" alt="vue">
      <img src="@/assets/img/tools/sass.png" alt="sass">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/git.png" alt="git">
      <img src="@/assets/img/tools/npm.png" alt="npm">
      <img src="@/assets/img/tools/vscode.png" alt="vscode">
      <img src="@/assets/img/tools/jira.png" alt="jira">
      <img src="@/assets/img/tools/bitbucket.png" alt="bitbucket">
      <img src="@/assets/img/tools/confluence.png" alt="confluence">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/teams.png" alt="teams">
    </Experience> -->
    <Experience company="DataOn" title="UI UX Engineer" period="Nov 2020 - Apr 2021" desc="
        I was involved to build SunFish HR SaaS, the biggest Human Resource Information System in Indonesia. I designed the feature (from ideation until the prototyping) then wrote the front-end code. I also started the SunFish Design System before I left this lovely team. At DataOn, we mostly use our own internal tools to develop.
      ">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/sass.png" alt="sass">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/jquery.png" alt="jquery">
      <img src="@/assets/img/tools/cf.png" alt="cf">
      <img src="@/assets/img/tools/semantic.png" alt="semantic">
      <img src="@/assets/img/tools/vscode.png" alt="vscode">
    </Experience>
    <Experience company="Binar Academy" title="UI/UX Design Facilitator" period="Oct 2020 - Feb 2021"
      parttime="Part-time" desc="
        I facilitated the UI/UX Design class (Wave 5) at Binar Academy for the gold and premium plan (7 chapters, 3 weeks for each chapter). We did it online and I still keep in touch with some of my students for the design project.
      ">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/miro.png" alt="miro">
      <img src="@/assets/img/tools/docs.png" alt="docs">
    </Experience>
    <Experience company="Solve Education!" title="UX Engineer" period="Sept 2017 - Oct 2020" desc="
        My first full-time job. I started as Software Developer on the Web Development team. As the team is small and keeps growing, I worked on many fields and ended up with the area between design and development. I was involved in Solve Education's web apps early stages like Solve Employment, Learnalytics, Content+, etc.
      ">
      <img src="@/assets/img/tools/laravel.png" alt="laravel">
      <img src="@/assets/img/tools/ci.png" alt="ci">
      <img src="@/assets/img/tools/python.png" alt="python">
      <img src="@/assets/img/tools/django.png" alt="django">
      <img src="@/assets/img/tools/sass.png" alt="sass">
      <img src="@/assets/img/tools/semantic.png" alt="semantic">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/jquery.png" alt="jquery">
      <img src="@/assets/img/tools/git.png" alt="git">
      <img src="@/assets/img/tools/npm.png" alt="npm">
      <img src="@/assets/img/tools/mantis.png" alt="mantis">
      <img src="@/assets/img/tools/jira.png" alt="jira">
      <img src="@/assets/img/tools/bitbucket.png" alt="bitbucket">
      <img src="@/assets/img/tools/gitlab.png" alt="gitlab">
      <img src="@/assets/img/tools/atom.png" alt="atom">
      <img src="@/assets/img/tools/pycharm.png" alt="pycharm">
      <img src="@/assets/img/tools/figma.png" alt="figma">
      <img src="@/assets/img/tools/ai.png" alt="ai">
      <img src="@/assets/img/tools/balsamiq.png" alt="balsamiq">
      <img src="@/assets/img/tools/docs.png" alt="docs">
      <img src="@/assets/img/tools/slack.png" alt="slack">
    </Experience>
    <Experience company="PT. Pindad (Persero)" title="Web Developer" period="Sep 2016 - Dec 2016" parttime="Internship"
      desc="
        I had internship at Information Technology Division Pindad, a well-known Indonesian state-owned enterprise specialising in military and commercial products. I develop an information system for budgeting called 'Sistem Informasi Rancangan Kinerja dan Anggaran (SI RKAP) PT Pindad (Persero)'.
      ">
      <img src="@/assets/img/tools/ci.png" alt="ci">
      <img src="@/assets/img/tools/semantic.png" alt="semantic">
      <img src="@/assets/img/tools/js.png" alt="js">
      <img src="@/assets/img/tools/jquery.png" alt="jquery">
      <img src="@/assets/img/tools/atom.png" alt="atom">
    </Experience>
  </div>
</template>

<script>
import Experience from '@/components/items/Experience.vue'

export default {
  name: 'ExperienceSection',
  components: {
    Experience
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/global.scss";

.br-experience {
  margin-top: 96px !important;
}
</style>
