<template>
  <div>
    <Menu />
    <div class="container br-kallan">
      <Title text="Kallan Agasatya Rachman" />
      <div class="container-wrapper-text">
        <img class="image" src="@/assets/kallan.jpg" alt="">
      </div>
      <div class="container-wrapper-text">
        <p>Dari rumpun bahasa Slavia, <span>Kallan</span> berarti aliran sungai. Sebagian makhluk menjadikannya habitat, juga bisa menjadi tempat yang suci bagi sebagian umat. Terkadang menjadi bencana yang menenggelamkan kota, namun juga berperan tuk pastikan kehidupan tetap terjaga. Ada yang berkata jika Kallan juga berarti yang terkuat dalam perang, sebagian lagi mengartikannya sebagai kesejahteraan (কল্যান). Mengalirlah, jaga kehidupan untuk apa yang di sekitar.</p>
        <p><span>Agasatya</span> adalah doa terberat. Bermakna baik, benar, jujur, murni, setia, sempurna, dan sangat diperlukan (ಅಗಸತ್ಯ). Dalam kamus yang lain, Aga sendiri berarti pohon, juga berarti pedang. Agasatya adalah nama yang berat, jadikanlah sebuah pengingat, nak, bukan menjadi satu beban di pundak.</p>
        <p>Asmaul Husna yang pertama (Ar Rahman / الرحمن), <span>Rachman</span> berarti Maha Pengasih. Diambil dari nama belakang ayahnya, semoga menjadi manusia yang penuh kasih seperti ibunya.</p>
        <p>Kallan terlahir dari ayah dan ibu yang biasa saja, tidak punya kuasa dan banyak harta, namun akan ada sampai akhir hayat untuknya dengan penuh perjuangan, kasih sayang, dan cinta. Kallan Agasatya Rachman lahir di Purwakarta, di tengah pandemi tahun 2021.</p>
      </div>
      <div class="container-wrapper-text">
        <p class="center">Sebuah lagu dari ayah, Pesan Untuk Kallan:</p>
        <audio controls>
          <source src="@/assets/kallan.mp3" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/global.scss";

  .br-kallan {
    .image {
      max-width: 100%;
      border-radius: 4px;
    }
    p {
      text-align: justify;

      span {
        background: $yellow;
        color: $black;
        font-weight: bold;
      }

      &.center {
        text-align: center;
      }
    }
  }
</style>
