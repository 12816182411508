<template>
  <div>
    <Menu />
    <div class="container br-works">
      <Title text="My Works" />
      <div class="container-wrapper-text">
        <p>
          Hi, there! Thank you for being here.
          Unfortunately, this page is under maintenance.
          But, no worries because you can visit some my works on some platforms.
        </p>
        <p>
          Will update all the works here soon. Stay tuned! :)
        </p>
      </div>
      <div class="container-wrapper">
        <iframe src="https://giphy.com/embed/3o7aCTfyhYawdOXcFW" width="300" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/animation-loop-3o7aCTfyhYawdOXcFW">via GIPHY</a></p>
      </div>
      <div class="container-wrapper-text temp">
        <Button text="See some design works on Behance" url="https://www.behance.net/banyurachman" newtab></Button>
        <Button text="See some simple animation loaders by pure CSS on Codepen" url="https://www.codepen.io/banyurachman" newtab></Button>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/global.scss";

  .br-works {
    p {

      span {
        background: $yellow;
        font-weight: bold;
      }

      &.center {
        text-align: center;
      }
    }

    &__card-showcase {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;
    }

    .temp {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
</style>
