<template>
  <div class="br-codepen">
    <div class="br-codepen__pen pen-1">
      <div id="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="br-codepen__pen pen-3">
      <div class="wrapper">
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
        <div class="ring"></div>
      </div>
    </div>
    <div class="br-codepen__pen pen-2">
      <div id="loader2">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Codepen'
}
</script>

<style scoped lang="scss">
@import "@/assets/global.scss";

  .br-codepen {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    @include phone {
      margin: 0;
    }

    &__pen {
      min-height: 200px;
      flex-basis: 250px;
      flex-shrink: 0;
      flex-grow: 1;
      display: grid;
      place-items: center;
      overflow: hidden;
    }
  }

  $blue: #4285F4;
  $red: #DB4437;
  $yellow: #F4B400;
  $green: #0F9D58;
  $grey: #BBBBBB;
  $movement: 7.5px;

  $loaders: 1 $blue translate($movement, $movement) (15px 0 0 0),
    2 $red translate(-$movement, $movement) (0 15px 0 0),
    3 $green translate($movement, -$movement) (0 0 0 15px),
    4 $yellow translate(-$movement, -$movement) (0 0 15px 0);
  $loaders2: 1 $blue,
    2 $red,
    3 $yellow,
    4 $green;
  $rotation: 0% 0deg,
    25% 90deg,
    50% 180deg,
    75% 270deg,
    100% 360deg;

  @each $number,
  $color,
  $translate,
  $border in $loaders {
    @keyframes child-#{$number} {
      50% {
        transform: $translate;
        opacity: 0.85;
        border-radius: $border;
        box-shadow: 0 0 15px 5px #{$color}44;
      }
    }
  }

  @each $number,
  $color in $loaders2 {
    @keyframes movement-#{$number} {
      25% {
        transform: translateX(10px);

        @if $number !=1 {
          box-shadow: 0 0 0 5px #{$color}44;
        }
      }
    }
  }

  @keyframes loader {
    @each $percentage,
    $deg in $rotation {
      #{$percentage} {
        transform: rotate($deg);
      }
    }
  }

  #loader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    height: 100px;
    width: 100px;
    animation:
      loader 4s cubic-bezier(0, 1, 1, 1) infinite;

    div {
      border-radius: 10px;
      @each $number,
      $color in $loaders {
        &:nth-child(#{$number}) {
          background: $color;
          animation: child-#{$number} 1s cubic-bezier(0, 1, 1, .57) infinite;
        }
      }
    }
  }

  #loader2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    div {
      width: 25px;
      height: 25px;
      border-radius: 100%;

      @each $number,
      $color in $loaders2 {
        &:nth-child(#{$number}) {
          background: #{$color};
          animation: movement-#{$number} 1s ease-out #{$number/4}s infinite;
        }
      }
    }
  }
    @mixin rectangle ($size) {
      height: $size;
      width: $size;
    }
  
    @mixin round {
      border-radius: 100%;
    }
  
    @keyframes wrapper_animate {
      0% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg);
      }
    }
  
    @keyframes ring_animate {
      0% {
        @include rectangle(15%);
        transform: scale(1);
        // background: #{$blue}75;
      }
  
      50% {
        // opacity: 0.7;
        // background: #{$green}75;
      }
  
      100% {
        @include rectangle(150%);
        transform: scale(0.5);
        opacity: 0.25;
        // background: #{$red}75;
      }
    }
  
    .wrapper {
      @include rectangle(150px);
      position: relative;
      animation:
        wrapper_animate 3s ease-in-out infinite alternate;
    }
  
    .ring {
      @include rectangle(100%);
      @include round;
      display: flex;
      position: absolute;
      justify-content: center;
      background: transparent;
  
      &:after {
        content: '';
        @include round;
        display: block;
        background: #{$blue};
        animation:
          ring_animate 2s ease-in-out infinite alternate;
      }
      &:nth-child(5n+0)::after {
        background: #{$red};
      }
      &:nth-child(5n+1)::after {
        background: #{$yellow};
      }
      &:nth-child(5n+2)::after {
        background: #{$green};
      }
      &:nth-child(5n+3)::after {
        background: #{$grey};
      }
    }
  
    @for $i from 1 through 10 {
      .ring:nth-child(#{$i}) {
        transform: rotate(#{36 * $i}deg);
      }
    }
</style>
